import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'reactstrap'
import RenderBlock from 'components/ui/block-text'
import Image from 'components/ui/image'
import Anchor from 'components/ui/anchor-link'

const Believe = ({ id, title, description, background, cta }) => {
  return (
    <section id={id} className="main-believe position-relative">
      <Image
        data={background}
        className="position-absolute main-believe__background"
      />
      <Container className="h-100">
        <Row className="h-100 pb-5 pb-md-0 align-items-end align-items-md-center ">
          <Col xs={8} md={7}>
            <article className="mb-5">
              <RenderBlock data={title} className="main-believe__title" />
              <RenderBlock
                data={description}
                className="main-believe__description"
              />
            </article>
            <Anchor
              type={cta?.type}
              link={cta?.link}
              text={cta?.text}
              className="main-button white-red d-inline-block"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Believe

Believe.defaultProps = {
  title: null,
  description: null,
  background: null,
  cta: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
  }),
}

Believe.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({}),
  description: PropTypes.shape({}),
  background: PropTypes.shape({}),
  cta: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
  }),
}

export const BelieveSection = graphql`
  fragment BelieveSection on SanityHomeBelieveSection {
    enable
    _rawTitle
    _rawDescription
    cta {
      ...ctaComponent
    }
    background {
      ...imageComponent
    }
  }
`
