import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import '../graphql/main-fragments'
import '../graphql/appereance'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Hero from 'components/home/hero'
import Partners from 'components/home/partners'

import '../graphql/home'
import WhatWeDo from 'components/home/what-we-do'
import Services from 'components/home/services'
import Believe from 'components/home/believe'
import Contact from 'components/contact'

const IndexPage = ({ data }) => {
  const {
    sanityPage: {
      tabs: { seo, hero, partners, whatWeDo, services, believe },
    },
    sanityAppereance: {
      tabs: { menu, footer },
    },
  } = data

  return (
    <Layout navbar={menu} footer={footer}>
      <SEO data={seo} />
      {hero?.enable && (
        <Hero
          id="home"
          title={hero?._rawTitle}
          description={hero?._rawDescription}
          cta={hero?.cta}
        />
      )}
      {partners?.enable && (
        <Partners
          id="our-partners"
          title={partners?._rawTitle}
          description={partners?._rawDescription}
          items={partners?.items}
        />
      )}
      {whatWeDo?.enable && (
        <WhatWeDo
          id="what-we-do"
          title={whatWeDo?._rawTitle}
          description={whatWeDo?._rawDescription}
          items={whatWeDo?.items}
          cta={whatWeDo?.cta}
        />
      )}
      {services?.enable && (
        <Services
          id="our-services"
          title={services?._rawTitle}
          items={services?.items}
          cta={services?.cta}
        />
      )}
      {believe?.enable && (
        <Believe
          id="believe"
          title={believe?._rawTitle}
          description={believe?._rawDescription}
          background={believe?.background}
          cta={believe?.cta}
        />
      )}
      <Contact />
    </Layout>
  )
}

export default IndexPage

export const IndexQuery = graphql`
  query HomeQuery {
    sanityPage(slug: { current: { eq: "home" } }) {
      ...IndexParts
    }
    sanityAppereance(sanityId: { eq: "Home" }) {
      ...AppereanceFragment
    }
  }
`

IndexPage.defaultProps = {
  data: {},
}

IndexPage.propTypes = {
  data: PropTypes.shape(PropTypes.object),
}
