import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Col, Row, Container } from 'reactstrap'
import BackgroundVideo from 'react-background-video-player'
import RenderBlock from 'components/ui/block-text'

import Anchor from 'components/ui/anchor-link'

const Hero = ({ id, title, description, cta }) => {
  return (
    <section id={id} className="main-hero">
      <BackgroundVideo
        className="main-hero__video"
        src="https://s3.amazonaws.com/cms.rvdev.com/leadteam-video.mp4"
        loop
        autoPlay
        volume={0}
      />
      <Container className="h-100">
        <Row className="h-100 justify-content-center align-items-center">
          <Col xs={12} md={10} className="text-center">
            <article className="pb-3 pb-md-5">
              <RenderBlock data={title} className="main-hero__title" />
              <RenderBlock
                data={description}
                className="main-hero__description px-md-5"
              />
            </article>
            <Anchor
              type={cta?.type}
              link={cta?.link}
              text={cta?.text}
              className="main-button default d-block d-md-inline-block"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Hero

Hero.defaultProps = {
  title: null,
  description: null,
  cta: null,
}

Hero.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({}),
  description: PropTypes.shape({}),
  cta: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
  }),
}

export const HeroSection = graphql`
  fragment HeroSection on SanityHomeHeroSection {
    enable
    _rawTitle
    _rawDescription
    background {
      ...imageComponent
    }
    cta {
      ...ctaComponent
    }
  }
`
