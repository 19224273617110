import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import Slider from 'react-slick'

import RenderBlock from 'components/ui/block-text'
import Image from 'components/ui/image'
import Anchor from 'components/ui/anchor-link'

const Services = ({ id, title, items, cta }) => {
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: '50px',
    infinite: false,
  }

  return (
    <section id={id} className="main-services py-5">
      <Container className="my-5">
        <Row>
          <Col xs={12} className="mb-5">
            <RenderBlock
              data={title}
              className="main-services__title text-center text-md-left"
            />
          </Col>
        </Row>
        <Row className="mt-4 d-none d-md-flex justify-content-center">
          {items?.map(item => (
            <Col
              key={item?._key}
              xs={6}
              md={4}
              className="main-services-item text-left mb-5 pl-3"
            >
              <Image
                data={item?.image}
                className="mb-3 main-services-item__image"
              />
              <RenderBlock
                data={item?._rawTitle}
                className="main-services-item__title"
              />
              <RenderBlock
                data={item?._rawDescription}
                className="main-services-item__description"
              />
            </Col>
          ))}
        </Row>
        <Slider
          {...settings}
          className="d-md-none mb-5 main-services-item__slide"
        >
          {items?.map(item => (
            <Col
              key={item?._key}
              xs={12}
              className="main-services-item text-left"
            >
              <article className="main-services-item__container">
                <Image
                  data={item?.image}
                  className="mb-3 main-services-item__image"
                />
                <RenderBlock
                  data={item?._rawTitle}
                  className="main-services-item__title"
                />
                <RenderBlock
                  data={item?._rawDescription}
                  className="main-services-item__description"
                />
              </article>
            </Col>
          ))}
        </Slider>
        <Row className="justify-content-center">
          <Col xs={12} md={5}>
            <Anchor
              type={cta?.type}
              link={cta?.link}
              text={cta?.text}
              className="main-button white d-block"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Services

Services.defaultProps = {
  title: null,
  items: [],
  cta: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
  }),
}

Services.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({}),
  items: PropTypes.arrayOf({}),
  cta: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
  }),
}

export const ServicesSection = graphql`
  fragment ServicesSection on SanityHomeServicesSection {
    enable
    _rawTitle
    cta {
      ...ctaComponent
    }
    items {
      _rawTitle
      _rawDescription
      image {
        ...imageComponent
      }
    }
  }
`
