import React, { useCallback, useRef } from 'react'
import { graphql } from 'gatsby'
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  Container,
} from 'reactstrap'
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import querystring from 'querystring'

const Contact = () => {
  const formInstance = useRef()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()
      if (!executeRecaptcha) {
        return
      }
      const action = 'contact_us'
      const result = await executeRecaptcha(action)
      if (result) {
        const formData = new FormData(formInstance.current)
        formData.append('to', 'djhom@redvalley.com')
        formData.append('redirect', 'https://www.leadteam.com/')
        formData.append('site', 'https://www.leadteam.com/')
        formData.append('token', result)
        formData.append('key', process.env.GATSBY_GOGGLE_RECAPTCHA_SECRET_KEY)
        formData.append('action', action)
        let object = {
          cc: [],
        }
        formData.forEach((value, key) => {
          object = { ...object, [key]: value }
        })
        const query = querystring.stringify(object)
        fetch(
          'https://l6kg4l50gi.execute-api.us-east-1.amazonaws.com/send-forms',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: query,
          }
        )
      }
    },
    [executeRecaptcha]
  )

  return (
    <section className="contact" id="my_id">
      <Container>
        <Row className="mx-0 px-4">
          <Col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            className="d-flex justify-content-center"
          >
            <div>
              <h2 className="contact__title">
                GET IN TOUCH <span className="contact__line" />
              </h2>
            </div>
          </Col>
          <Col
            sm="12"
            md="12"
            lg="6"
            xl="6"
            className="d-flex align-items-center justify-content-center"
          >
            <form
              ref={formInstance}
              className="contact__form"
              onSubmit={handleSubmit}
            >
              <FormGroup>
                <Label for="name" hidden>
                  Name
                </Label>
                <Input type="text" name="name" id="name" placeholder="Name" />
              </FormGroup>
              <FormGroup>
                <Label for="email" hidden>
                  Password
                </Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                />
              </FormGroup>
              <FormGroup>
                <Label for="message" hidden>
                  Message
                </Label>
                <Input
                  type="text"
                  name="message"
                  id="message"
                  placeholder="Your Message"
                />
              </FormGroup>
              <Button
                outline
                color="secondary"
                className="main-button outline-white--slim w-auto d-block"
                type="submit"
              >
                Get in touch
              </Button>
              <GoogleReCaptcha />
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Contact

export const ContactSection = graphql`
  fragment ContactSection on SanitySectionContact {
    enable
    _rawTitle
    _rawDescription
    redes {
      _key
      type
      target
      text
      link
      enable
      className
    }
  }
`
