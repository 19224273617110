import React from 'react'
import PropTypes from 'prop-types'
import RenderBlock from 'components/ui/block-text'
import { Collapse, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

const Item = ({ isOpen, toggle, title, description, id }) => {
  return (
    <>
      <Row
        onClick={toggle(id)}
        className="justify-content-center align-items-center pb-3 px-0 m-0"
      >
        <Col xs={12} className="px-0">
          <div className="main-whatwedo-item__title-container">
            <h3 className="d-inline main-whatwedo-item__index mr-2">
              {id > 10 ? id + 1 : `0${id + 1}`}
            </h3>
            <RenderBlock
              data={title}
              className="main-whatwedo-item__title p-0 m-0 pr-5 text-left"
            />
            <Button
              aria-label={`project ${id}`}
              className={classnames('main-whatwedo-item__open', {
                times: isOpen,
              })}
            >
              <FontAwesomeIcon icon="plus" />
            </Button>
          </div>
        </Col>
      </Row>
      <Collapse isOpen={isOpen} className="">
        <article className="">
          <RenderBlock
            data={description}
            className="main-whatwedo-item__description p-0 m-0 pr-5 text-left"
          />
        </article>
      </Collapse>
    </>
  )
}

export default Item

Item.defaultProps = {
  isOpen: false,
  toggle: null,
  title: null,
  description: null,
  id: null,
}

Item.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.shape(PropTypes.object),
  description: PropTypes.shape(PropTypes.object),
  id: PropTypes.number,
}
