import React from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'reactstrap'
import { useMediaQueries } from '@react-hook/media-query'

import RenderBlock from 'components/ui/block-text'
import Image from 'components/ui/image'

const Partners = ({ id, title, description, items }) => {
  const {
    matches: { mobileSlide, tabletSlide },
  } = useMediaQueries({
    mobileSlide: 'only screen and (max-width: 580px)',
    tabletSlide: 'only screen and (max-width: 850px) and (min-width: 581px) ',
  })
  const slidesQuantity = (mobileSlide && 1) || (tabletSlide && 3) || 5
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: items.length < slidesQuantity ? items.length : slidesQuantity,
    slidesToScroll: 1,
    arrows: false,
  }

  return (
    <section id={id} className="main-partners p-5">
      <Container className="my-5">
        <Row className="h-100 justify-content-center align-items-center">
          <Col xs={12} md={10} className="text-center">
            <article className="pb-5">
              <RenderBlock data={title} className="main-partners__title mb-4" />
              <RenderBlock
                data={description}
                className="main-partners__description mb-0"
              />
            </article>
          </Col>
        </Row>
        <Slider
          {...settings}
          className="main-partners__slider  px-0 px-lg-5 mb-4"
        >
          {items?.map(item => (
            <Image
              key={item?._key}
              data={item}
              className="w-100 px-md-0 align-middle"
            />
          ))}
        </Slider>
      </Container>
    </section>
  )
}

export default Partners

Partners.defaultProps = {
  title: null,
  description: null,
  items: [],
}

Partners.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({}),
  description: PropTypes.shape({}),
  items: PropTypes.arrayOf({}),
}

export const PartnersSection = graphql`
  fragment PartnersSection on SanityHomePartnersSection {
    enable
    _rawTitle
    _rawDescription
    items {
      ...imageComponent
    }
  }
`
