import React, { useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'reactstrap'

import RenderBlock from 'components/ui/block-text'
import Anchor from 'components/ui/anchor-link'
import Image from 'components/ui/image'
import Item from './item'

const WhatWeDo = ({ id, title, description, items, cta }) => {
  const mapBlock = items.map((_, index) => index === 0)
  const [isOpenBlock, setIsOpenBlock] = useState(mapBlock)
  const [currentImage, setCurrentImage] = useState(items[0]?.image)

  const handleToogleCollapse = index => () => {
    const opens = isOpenBlock.map(() => false)
    opens[index] = !opens[index]
    setCurrentImage(items[index]?.image)
    setIsOpenBlock(opens)
  }

  return (
    <section id={id} className="main-whatwedo py-5">
      <Container className="mt-5 py-3">
        <Row className="h-100 justify-content-center align-items-center">
          <Col xs={10} md={10} className="text-center">
            <article className="pb-5">
              <RenderBlock data={title} className="main-whatwedo__title mb-4" />
              <RenderBlock
                data={description}
                className="main-whatwedo__description mb-0"
              />
            </article>
          </Col>
        </Row>
      </Container>
      <Row className="mx-0 mb-5">
        <Col xs={12} md={7} className="px-0">
          <Image
            data={currentImage}
            className="w-100 main-whatwedo__image mb-4 mb-md-0"
          />
        </Col>
        <Col xs={12} md={4} className="px-4">
          {items.map((item, index) => (
            <Item
              key={`whawedo-${item?._key}`}
              id={index}
              title={item?._rawTitle}
              description={item?._rawDescription}
              isOpen={isOpenBlock[index]}
              toggle={handleToogleCollapse}
            />
          ))}
          <Anchor
            type={cta?.type}
            link={cta?.link}
            text={cta?.text}
            className="main-button default d-inline-block mt-5"
          />
        </Col>
      </Row>
    </section>
  )
}

export default WhatWeDo

WhatWeDo.defaultProps = {
  title: null,
  description: null,
  items: [],
  cta: null,
}

WhatWeDo.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.shape({}),
  description: PropTypes.shape({}),
  items: PropTypes.arrayOf({}),
  cta: PropTypes.shape({
    type: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
  }),
}

export const WhatWeDoSection = graphql`
  fragment WhatWeDoSection on SanityHomeWhatWeDoSection {
    enable
    _rawTitle
    _rawDescription
    cta {
      ...ctaComponent
    }
    items {
      _key
      _rawTitle
      _rawDescription
      image {
        ...imageComponent
      }
    }
  }
`
